import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Absencetype, Department, GenderEnum, Project, ReportDefinition } from '@uni-entities';
import { IModalOptions, IUniModal, UniModalService, UniPreviewModal } from '@uni-framework/uni-modal';
import { ISelectConfig } from '@uni-framework/ui/uniform';
import { forkJoin } from 'rxjs';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { DepartmentService } from '@app/services/common/departmentService';
import { ProjectService } from '@app/services/common/projectService';

@Component({
    styleUrls: ['./absence-report.sass'],
    template: `
        <section role="dialog" class="uni-modal" style="width: 45rem">
            <header>Generer fraværstatistikk</header>

            <article>
                <section class="alert good" style="transition: height .4s ease">
                    <i class="material-icons">support</i>
                    <section class="flex-column">
                        <span>
                            Mer informasjon om grunnlaget for rapporten
                            <a class="ml-3 color-c2a" (click)="expanded = !expanded">
                                {{ expanded ? 'Vis mindre' : 'Les mer' }}
                            </a></span
                        >
                        <span class="mt-1" style="font-size: 13px;" *ngIf="expanded">
                            <b>* Fraværsprosent </b>
                            <p>
                                Beregnes ut fra en persons registrerte sykefraværsprosent multiplisert med den ansattes
                                stillingsprosent.
                            </p>
                            <b>* Tilfeller </b>
                            <p>
                                Er en antall ganger en person har registrert sykefravær i gjeldende periode.Det regnes
                                som nytt sykefravær når fraværet ikke er sammenhengende.Ett fravær som går over flere
                                perioder vil bare være registrert som tilfelle i den periode fraværet oppstår.
                            </p>
                            <b>* Mulige årsverk</b>
                            <p>
                                Årsverk beregnes ut fra mulige dagsverk tilgjengelig i en periode.Det tas hensyn til om
                                ett arbeidsforhold starter eller slutter i perioden.Ferie som er avviklet i perioden er
                                ikke med i mulige dagsverk.
                            </p>
                        </span>
                    </section>
                </section>

                <label class="uni-label label-left">
                    <span>År</span>
                    <uni-select [config]="selectYearConfig" [items]="selectYear" [(value)]="year"> </uni-select>
                </label>
                <label class="uni-label label-left">
                    <span>Avdeling</span>
                    <uni-select
                        [items]="departments"
                        [(value)]="currentDepartment"
                        [config]="{ displayProperty: 'Name' }"
                    ></uni-select>
                </label>
                <label class="uni-label label-left ">
                    <span>Prosjekt</span>
                    <uni-select
                        [items]="projects"
                        [(value)]="currentProject"
                        [config]="{ displayProperty: 'Name' }"
                    ></uni-select>
                </label>
                <label class="uni-label label-left">
                    <span>Fraværstyper</span>
                    <section class="checkbox-list flex gap-2 bg-info border rounded">
                        <rig-checkbox [(ngModel)]="filterSickLeave">Sykemelding</rig-checkbox>
                        <rig-checkbox [(ngModel)]="filterSickChild">Sykt barn</rig-checkbox>
                    </section>
                </label>
                <label class="uni-label label-left">
                    <span>Kjønn</span>
                    <section class="checkbox-list flex gap-2 bg-info border rounded">
                        <rig-checkbox [(ngModel)]="filterMen">Menn</rig-checkbox>&nbsp;
                        <rig-checkbox [(ngModel)]="filterWomen">Kvinner</rig-checkbox>
                    </section>
                </label>
            </article>

            <footer>
                <button class="secondary" (click)="onClose.emit()">Avbryt</button>
                <button class="c2a" (click)="showReport()">Opprett rapport</button>
            </footer>
        </section>
    `,
    standalone: false,
})
export class AbsenceReportModal implements IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    year: number;
    report: ReportDefinition;

    selectYearConfig: ISelectConfig;
    selectYear: number[];

    departments: any[] = [];
    currentDepartment: Department;
    projects: any[] = [];
    currentProject: Project;

    filterMen: boolean;
    filterWomen: boolean;
    filterSickChild: boolean;
    filterSickLeave: boolean;

    expanded: boolean;

    selectOptions: ISelectConfig = {
        displayProperty: 'name',
    };

    constructor(
        private modalService: UniModalService,
        private financialYearService: FinancialYearService,
        private departmentService: DepartmentService,
        private projectService: ProjectService,
    ) {
        this.selectYearConfig = {
            template: (item) => item,
            searchable: false,
            hideDeleteButton: true,
        };
    }

    ngOnInit() {
        this.year = this.financialYearService.getActiveYear();
        this.report = this.options?.data || {};
        this.selectYear = this.getYearComboSelection(this.year);

        forkJoin([this.departmentService.GetAll(null), this.projectService.GetAll(null)]).subscribe((result) => {
            this.departments = result[0];
            this.projects = result[1];

            // Add all and no dimensions
            this.departments.unshift({ Name: 'Alle', ID: 'ALLDEPARTMENTSID', DepartmentNumber: 0 });
            this.departments.push({ Name: 'Ingen avdeling', ID: 0 });

            this.projects.unshift({ Name: 'Alle', ID: 'ALLPROJECTSID', ProjectNumber: 0 });
            this.projects.push({ Name: 'Ingen prosjekt', ID: 0 });

            this.currentDepartment = this.departments[0];
            this.currentProject = this.projects[0];
        });
    }

    private getYearComboSelection(curYear): number[] {
        return [curYear + 1, curYear, curYear - 1, curYear - 2];
    }

    showReport() {
        this.report['parameters'] = [{ Name: 'Year', value: this.year }];
        if (this.currentDepartment.ID > 0) {
            this.report['parameters'].push(...[{ Name: 'DepartmentID', value: this.currentDepartment.ID }]);
        }
        if (this.currentProject.ID > 0) {
            this.report['parameters'].push(...[{ Name: 'ProjectID', value: this.currentProject.ID }]);
        }
        if (this.filterMen && !this.filterWomen) {
            this.report['parameters'].push(...[{ Name: 'Sex', value: GenderEnum.Man }]);
        } else if (this.filterWomen && !this.filterMen) {
            this.report['parameters'].push(...[{ Name: 'Sex', value: GenderEnum.Woman }]);
        } else {
            this.report['parameters'].push(...[{ Name: 'Sex', value: null }]);
        }
        if (this.filterSickChild || this.filterSickLeave) {
            let absenceFilter = this.filterSickChild ? Absencetype.SickChild.toString() : '';
            absenceFilter += this.filterSickLeave
                ? absenceFilter.length > 0
                    ? ',' + Absencetype.SickLeave.toString()
                    : Absencetype.SickLeave.toString()
                : '';

            this.report['parameters'].push(...[{ Name: 'Absencetypes', value: absenceFilter }]);
        } else {
            this.report['parameters'].push(...[{ Name: 'Absencetypes', value: null }]);
        }

        this.modalService.open(UniPreviewModal, { data: this.report });
        this.onClose.emit();
    }
}
