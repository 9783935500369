import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IComplexInput } from '../inputarray/inputarraycomponent';
import { rigDate } from '@app/components/common/utils/rig-date';

@Component({
    selector: 'filter-dropdown',
    templateUrl: './filter-dropdown.html',
    styleUrls: ['./filter-dropdown.sass'],
    standalone: false,
})
export class ReportFilterDropdown {
    @Input() inputs: IComplexInput[];
    @Input() showInline: boolean;
    @Output() inputChange = new EventEmitter();

    // activeFilterCount: number;

    // ngOnChanges() {
    //     this.updateActiveFilterCount();
    //     console.log(this.inputs);
    // }

    // private updateActiveFilterCount() {
    //     this.activeFilterCount = this.inputs?.filter(input => !!input.value).length;
    // }

    onDateChanged(input: IComplexInput) {
        input.text = rigDate(input.value).format('YYYY-MM-DD');
        input.mapsTo.forEach((x) => (x.Default = input.text));
        this.inputChange.emit({ inputs: input.mapsTo, layout: undefined });
        // this.updateActiveFilterCount();
    }

    onNumericChanged(input: IComplexInput) {
        input.text = input.value;
        input.mapsTo.forEach((x) => (x.Default = input.text));
        this.inputChange.emit({ inputs: input.mapsTo, layout: undefined });
    }

    onDropdownSelected(item, input: IComplexInput) {
        input.value = item;
        input.mapsTo.forEach((x) => {
            x.Default = item.Value;
        });
        this.inputChange.emit({ inputs: input.mapsTo, layout: undefined });
    }

    onDimSelected(input: IComplexInput) {
        input.mapsTo.forEach((x) => {
            x.Default = input.value?.value ?? input.value?.Value ?? '';
        });
        this.inputChange.emit({ inputs: input.mapsTo, layout: undefined });
        // this.updateActiveFilterCount();
    }

    onRangeSelected(input: IComplexInput, range: { from: number; to: number }) {
        if (input.mapsTo.length === 2) {
            input.mapsTo[0].Default = range.to > 0 ? range.from.toString() : '';
            input.mapsTo[1].Default = range.to > 0 ? range.to.toString() : '';
        } else if (input.mapsTo.length === 1) {
            input.mapsTo[0].Default = range.from === 0 && range.to === 0 ? '' : `${range.from}-${range.to}`;
        }
        this.inputChange.emit({ inputs: input.mapsTo, layout: undefined });
    }

    onSwitch(input: IComplexInput) {
        input.mapsTo.forEach((x) => (x.Default = '' + (input.value ? 1 : 0)));
        this.inputChange.emit({ inputs: input.mapsTo, layout: undefined });
    }

    onInputClick(input: IComplexInput) {
        // allow user to modify "other" inputs not supported here
        this.inputChange.emit({ inputs: [input], layout: undefined, action: 'edit-options' });
    }

    flipRange(input: IComplexInput) {
        input.range = input.range === 'on' ? 'off' : 'on';
        if (input.range === 'off') {
            // Remove active range ?
            if (this.hasActiveRange(input)) {
                input.mapsTo.forEach((x) => (x.Default = ''));
                this.inputChange.emit({ inputs: input.mapsTo, layout: undefined });
            }
        }
    }

    hasActiveRange(input: IComplexInput) {
        return !!input.mapsTo[0].Default && !!input.mapsTo[1].Default;
    }
}
