import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BizReportService } from '../biz-report-service';
import { DrilldownService } from '../drilldown/drilldown-service';
import { IBizReport, IBizReportLayout } from '../IBizReport';
import { IComplexInput } from '../inputarray/inputarraycomponent';

@Component({
    selector: 'report-settings-dropdown',
    templateUrl: './report-settings-dropdown.html',
    styleUrls: ['./report-settings-dropdown.sass'],
    standalone: false,
})
export class ReportSettingsDropdown {
    @Input() report: IBizReport;
    @Input() layout: IBizReportLayout;
    @Input() flipSignInput: IComplexInput;

    @Output() flipSignChange = new EventEmitter();
    @Output() viewSettingChange = new EventEmitter();

    hasGrouping: boolean;
    showGroupContentCheckboxLabel = 'Vis gruppedata';

    constructor(
        public reportService: BizReportService,
        public drilldownService: DrilldownService,
    ) {}

    ngOnChanges(changes) {
        if (changes['layout'] && this.layout) {
            this.hasGrouping = !!this.layout.Grouping?.Source;
        }

        if (changes['report'] && this.report) {
            const reportName = this.report?.Name;

            if (reportName === 'Incomestatement' || reportName === 'Balance') {
                this.showGroupContentCheckboxLabel = 'Vis kontoer';
            }
        }
    }

    onFlipSignChange() {
        this.flipSignInput.mapsTo.forEach((x) => (x.Default = '' + (this.flipSignInput.value ? 1 : 0)));
        this.flipSignChange.emit({ inputs: this.flipSignInput.mapsTo, layout: undefined });
    }
}
