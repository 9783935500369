import { Component } from '@angular/core';
import { CustomerInvoiceService } from '@app/services/sales/customerInvoiceService';

@Component({
    selector: 'uni-sold-products-drilldown',
    templateUrl: './sold-products-drilldown.component.html',
    styleUrls: ['./sold-products-drilldown.component.sass'],
    standalone: false,
})
export class SoldProductsDrilldownComponent {
    drilldownRoute: { url: string; queryParams?: any };
    params: any;
    enableStickyMode: () => void;

    dataLoaded: boolean;
    data: any[];

    constructor(private customerInvoiceService: CustomerInvoiceService) {}

    ngOnInit() {
        if (this.params) {
            this.loadData(this.params);
        }
    }

    private loadData(params: any) {
        this.customerInvoiceService
            .GetAll(
                `expand=Items&filter=Items.ProductID eq ${params.productID} and InvoiceDate ge '${params.filterFromDate}' and InvoiceDate le '${params.filterToDate}'`,
            )
            .subscribe({
                next: (data) => (this.data = data),
                complete: () => (this.dataLoaded = true),
            });
    }
}
