import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { switchMap, map } from 'rxjs/operators';
import { ImageModal } from '@app/components/common/modals/ImageModal';
import { UniModalService } from '@uni-framework/uni-modal';
import { JournalEntryLineService } from '@app/services/accounting/journalEntryLineService';
import { ErrorService } from '@app/services/common/errorService';
import { StatisticsService } from '@app/services/common/statisticsService';

@Component({
    selector: 'open-posts-drilldown',
    templateUrl: './open-posts-drilldown.html',
    styleUrls: ['./open-posts-drilldown.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class OpenPostsDrilldown {
    drilldownRoute: { url: string; queryParams?: any };
    params: any;
    enableStickyMode: () => void;

    filterTags: string[];
    dataLoaded: boolean;
    data: any[];

    constructor(
        private errorService: ErrorService,
        private cdr: ChangeDetectorRef,
        private modalService: UniModalService,
        private journalEntryLineService: JournalEntryLineService,
        private statisticsService: StatisticsService,
    ) {}

    ngOnInit() {
        if (this.params) {
            this.filterTags = ['Konto: ' + this.params.number];
            this.loadData();
        }
    }

    private loadData() {
        this.getCustomerAndSupplierID(this.params.accountID)
            .pipe(
                switchMap((res) => {
                    return this.journalEntryLineService.getJournalEntryLinePostPostData(
                        true,
                        false,
                        res.CustomerID,
                        res.SupplierID,
                        undefined,
                        undefined,
                        0,
                        50,
                    );
                }),
            )
            .subscribe({
                next: (data) => {
                    this.data = data;
                    this.dataLoaded = true;
                    this.cdr.markForCheck();
                },
                error: (err) => this.errorService.handle(err),
            });
    }

    showAttachments(row) {
        if (row.JournalEntryID) {
            this.modalService.open(ImageModal, {
                data: {
                    entity: 'JournalEntry',
                    entityID: row.JournalEntryID,
                    readonly: true,
                },
            });

            this.enableStickyMode();
        }
    }

    private getCustomerAndSupplierID(accountID: number) {
        const query = `model=Account&filter=ID eq ${accountID}&select=ID,CustomerID as CustomerID,SupplierID as SupplierID`;
        return this.statisticsService.GetAllUnwrapped(query).pipe(map((res) => res && res[0]));
    }
}
